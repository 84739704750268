<template>
    <div>
        <ValidationObserver ref="form">
            <form @change="triggerProfileInfoFormChange">
                <div class="client-demo-card-header">
                    <h3>Configure Lookup Table</h3>
                </div>
                <div v-if="!lookupTableList.length" class="bg-white w-100">
                    <div class="d-grid justify-content-center align-items-center py-5" style="min-height: 50px">
                        <h5>No table is attached with dashboard</h5>
                    </div>
                </div>
                <div
                    v-for="data in lookupTableList"
                    :key="data.id"
                    class="client-demo client-profile-form general px-4"
                >
                    <div class="flex colx4 wrap">
                        <Multiselect
                            id="table"
                            track-by="id"
                            label="name"
                            placeholder="Search or Select Table"
                            :value="data"
                            :options="lookupTableList"
                            :searchable="true"
                            :allow-empty="false"
                            :show-labels="false"
                            :disabled="true"
                        />

                        <Multiselect
                            id="key"
                            track-by="id"
                            label="key"
                            placeholder="Search or Select Key"
                            :value="findKeyofTable(data)"
                            :options="data.lookupTable"
                            :searchable="true"
                            :allow-empty="false"
                            :show-labels="false"
                            @input="handleKeyFromTable(data, $event)"
                            :disabled="checkDisabled(data)"
                        />
                    </div>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
    import { ValidationObserver } from 'vee-validate';
    import { clients, lookupTable, memberProfile } from '@/util/apiRequests';
    import { Roles } from '@/util/globalConstants';

    export default {
        name: 'ClientDemographicsLookupTable',
        components: { ValidationObserver },
        props: {
            clientId: {
                type: [Number, String],
                default: 0,
            },
            clientDashboardInfo: {
                type: Object,
            },
            lookupTableData: {
                type: Array,
            },
        },
        data() {
            return {
                Roles,
                isProfileInfoFormChanged: false,
                lookupTableList: [],
                originalLookupTableList: [],
                dashboardInfo: null,
                lookup_table_data: [],
            };
        },
        computed: {
            userIsSuperAdmin() {
                if (this.$store.state.user.role_id == Roles.SUPER_ADMIN) {
                    return true;
                } else return false;
            },
            userIsAdmin() {
                if (this.$store.state.user.role_id == this.Roles.ADMIN) {
                    return true;
                }
                return false;
            },
            userIsClinician() {
                if (this.$store.state.user.role_id == this.Roles.CLINICIAN) {
                    return true;
                }
                return false;
            },
        },
        methods: {
            findKeyofTable(data) {
                let keyData = this.lookup_table_data?.find((item) => item.id === data.id);
                if (keyData?.keyId) {
                    return keyData;
                }
                return '';
            },
            async getAllLookupTable() {
                try {
                    let list = await this.$api.get(lookupTable.getAllLookupTable());
                    this.originalLookupTableList = list?.data?.info;
                } catch (err) {
                    this.$toasted.error('Failed to retrieve Look-up table list');
                }
            },
            async getLookupTableByDashboardIds() {
                try {
                    this.dashboardInfo = { ...this.clientDashboardInfo };

                    const dashboardKeys = [
                        'dashboardUsed',
                        'kiosk_dashboard_used',
                        'mhh_dashboard_used',
                        'family_connect_dashboard_used',
                        'star_kid_dashboard_used',
                        'mobex_plus_dashboard_used',
                        'mobex_health_home_dashboard_used',
                    ];

                    const ids = dashboardKeys.map((key) => this.dashboardInfo[key]).filter(Boolean);

                    const endpoint = memberProfile.getLookupTableByDashboardIds();
                    const res = await this.$api.post(endpoint, { ids });
                    const lookupTableIds = res?.data?.lookupTableIds || [];

                    this.lookupTableList = this.originalLookupTableList.filter((item) =>
                        lookupTableIds.includes(item.id)
                    );

                    if (!this.lookupTableList.length) {
                        this.lookup_table_data = [];
                    } else {
                        const existingIds = new Set(this.lookup_table_data.map((item) => item.id));
                        const newIds = lookupTableIds.filter((id) => !existingIds.has(id));

                        if (newIds.length) {
                            this.lookup_table_data.push(
                                ...newIds.map((id) => ({
                                    id,
                                    key: null,
                                    keyId: null,
                                }))
                            );
                        }

                        // Remove ids that are no longer present
                        this.lookup_table_data = this.lookup_table_data.filter((item) =>
                            lookupTableIds.includes(item.id)
                        );
                    }

                    this.$emit('handleLookupInfo', this.lookup_table_data);
                    this.isProfileInfoFormChanged = true;
                } catch (err) {
                    this.$toasted.error('Failed to retrieve Lookup table associated with dashboards');
                    console.error('Failed to retrieve Lookup table associated with dashboards', err);
                }
            },
            handleKeyFromTable(data, event) {
                this.lookup_table_data.map((item) => {
                    if (item.id === data.id) {
                        item.keyId = event.id;
                        item.key = event.key;
                    }
                });
                this.$emit('handleLookupInfo', this.lookup_table_data);
                this.isProfileInfoFormChanged = true;
            },
            checkDisabled(data) {
                if (this.userIsSuperAdmin) {
                    return false;
                }
                if (this.userIsAdmin && data.allowAdmin) {
                    return false;
                }
                if (this.userIsClinician && data.allowClinician) {
                    return false;
                }
                return true;
            },
            triggerProfileInfoFormChange() {
                this.isProfileInfoFormChanged = true;
            },
        },
        async created() {
            await this.getAllLookupTable();
            await this.getLookupTableByDashboardIds();
            this.$emit('clientLookupInfoChange', { context: this });
        },
        watch: {
            clientDashboardInfo: {
                async handler() {
                    await this.getLookupTableByDashboardIds();
                    this.$emit('clientLookupInfoChange', { context: this });
                },
                deep: true,
            },
        },
    };
</script>
